import { useRouter } from 'next/router';
import { NoResultsTemplate } from '@components/NoResultsTemplate/NoResultsTemplate';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';

export default function FourOhFour() {
  const router = useRouter();
  const { t } = useLocalization('errors');

  return (
    <NoResultsTemplate
      title={t('notFound.title')}
      subtitle={t('notFound.subtitle')}
      buttonText={t('notFound.goBackShopping')}
      onClick={() => router.push(routing.home)}
    />
  );
}

export function getStaticProps() {
  return {
    props: {
      hideShopBy: true,
    },
  };
}
